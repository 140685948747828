import React, { Component } from "react";
 
class Disclaimer extends Component {
  render() {
    return (
      <div className ="container" >
        <span>
                <p></p>
                <p>Disclaimer</p>
        <b>Catering</b>:<br/>
          
The information contained on this restaurant website is for general information purposes only. We strive to provide accurate and up-to-date information, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, menu items, promotions, or related graphics contained on the website for any purpose.

The menu items, pricing, and availability listed on the website are subject to change without notice. While we make every effort to ensure that the information on our website is current and accurate, we cannot guarantee that it reflects the most current offerings in our restaurant.

Any reliance you place on the information provided on this website is strictly at your own risk. We disclaim all liability for any loss, injury, or damage resulting from the use of this website or reliance on its content.

Please note that images of dishes on the website are for illustrative purposes only and may not necessarily represent the actual size, presentation, or ingredients of the dishes served in our restaurant.

We reserve the right to modify, suspend, or discontinue any aspect of the website at any time, including the availability of any features, without prior notice.

Links to third-party websites on this site are provided for your convenience. We have no control over the content or availability of those sites, and the inclusion of any links does not necessarily imply a recommendation or endorsement of the views expressed within them.

Thank you for your understanding, and we look forward to serving you at our restaurant.
        </span>
        <br/>
<span>

<b>Hairdresser/haircut services:</b><br/>

The information provided on this website is for general informational purposes regarding the services offered by our hair salon. While we strive to ensure that the information is accurate and up-to-date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.

Our hair salon services, including pricing and availability, are subject to change without prior notice. We make every effort to accurately display our services and prices on the website, but variations may occur, and we reserve the right to adjust prices and offerings at any time.

The images of hairstyles and hair treatments on the website are for illustrative purposes only and may not represent exact results. Each client's hair is unique, and outcomes may vary.

Clients are encouraged to schedule consultations to discuss individual hair care needs and preferences. The information provided on the website does not constitute professional advice, and personalized guidance should be sought for specific hair concerns.

We are committed to protecting the privacy and confidentiality of our clients. However, we cannot guarantee the security of information transmitted over the internet or stored digitally.

Links to third-party websites on this site are provided for informational purposes or convenience. We have no control over the content or availability of these sites and do not endorse or take responsibility for the content, products, or services offered.

By accessing and using this website, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of the website or services provided by our hair salon.

Thank you for choosing our hair salon, and we look forward to providing you with exceptional service.
</span>
        
        <br/>
        <span>
        <b>Food/Grocery/General Goods service:</b><br/>

The information provided on this website is for general informational purposes related to the products and services offered by our grocery and general goods store. While we strive to maintain accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the products listed.

Product availability, pricing, and descriptions are subject to change without prior notice. We make every effort to accurately display product information, but variations may occur, and we reserve the right to modify prices and offerings at any time.

Images of products on the website are for illustrative purposes only and may not represent the exact size, color, or packaging of the items available in our store. Customers are encouraged to review product labels and specifications for accurate details.

We take precautions to ensure the security and privacy of your information during online transactions. However, we cannot guarantee the security of data transmitted over the internet.

Links to third-party websites or resources may be provided for your convenience. We have no control over the content or availability of these sites and do not endorse or take responsibility for the content, products, or services offered.

The information on this website does not constitute professional advice, and users are encouraged to seek appropriate guidance for specific product usage, dietary needs, or other concerns.

By using our website and services, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of the website or products purchased from our store.

Thank you for choosing our grocery and general goods store. We appreciate your trust in our products and services.
        </span>

        <br/>
        <span>
        <b>Product Assembly Service: </b><br/>

The information provided on this website is for general informational purposes regarding the assembly services offered. While we strive to ensure the accuracy and reliability of the information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.

Our assembly services are subject to availability, and we reserve the right to modify or discontinue any aspect of our services without prior notice. The information on the website does not constitute professional advice, and users are encouraged to contact us directly for personalized assistance.

The assembly technicians engaged in providing our services are skilled professionals; however, we cannot guarantee the outcome or efficiency of assembly in all circumstances. While we take utmost care, we disclaim liability for any damage or loss that may occur during or as a result of the assembly process.

Customers are responsible for providing accurate and complete information about the items to be assembled, including any special requirements or considerations. Failure to provide such information may impact the quality of the assembly.

Links to third-party websites on this site are provided for informational purposes or convenience. We have no control over the content or availability of these sites and do not endorse or take responsibility for the content, products, or services offered.

By using our assembly services and accessing this website, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of the website or services provided by our assembly technicians.

Thank you for choosing our assembly services. We look forward to assisting you with professional and efficient assembly solutions.
    
        </span>
        <br/>
        <span>
        <b>Any service provided on this web site:</b><br/>
The information provided on this website is intended for general informational purposes related to the services we offer. While we make every effort to ensure the accuracy and reliability of the information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the services mentioned.

Our services are subject to change, and we reserve the right to modify, suspend, or discontinue any aspect of our services without prior notice. The information provided on the website does not constitute professional advice, and users are encouraged to seek appropriate professional guidance tailored to their specific needs.

We do not guarantee the continuous availability of our services or the website, and we disclaim any liability for any temporary unavailability or technical issues that may arise.

While we take measures to secure our website and services, we cannot guarantee that they will be free from unauthorized access or use. Users are responsible for maintaining the confidentiality of their account information and passwords.

In no event will we be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of the use or inability to use our services or website, even if we have been advised of the possibility of such damages.

Links to third-party websites or resources may be provided for your convenience. We have no control over the content or availability of these sites and do not endorse or take responsibility for the content, products, or services offered.

By using our services and accessing this website, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of the website or services.

Thank you for your understanding, and we look forward to serving you with our services.
        </span>
<br/>
<p></p>
  </div>
    );
  }
}
 
export default Disclaimer;